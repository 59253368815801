<template>
  <v-dialog v-model='isOpen' max-width='500px'>
    <v-card v-if="action === 'suspend'" class='d-flex flex-column align-start pa-2'>
      <v-card-title class='text-center mb-5'>
        <v-icon class='mr-4 white--text' color='warning' large left>
          mdi-alert
        </v-icon>
        Suspend Account
      </v-card-title>
      <v-card-subtitle>
        <b>
          You are about to suspend your account, are you sure you want to proceed?
        </b>
      </v-card-subtitle>
      <v-card-actions class='align-self-center'>
        <v-spacer></v-spacer>
        <v-btn
          class='px-14 mx-4 font-weight-bold white--text'
          color='primary'
          large
          outlined
          @click="$emit('toggleStatusDialog', 'suspend')"
        >
          Cancel
        </v-btn>
        <v-btn
          class='px-14 mx-4 font-weight-bold white--text'
          color='primary'
          large
          @click="$emit('changeAccountStatus', 'suspend')"
        >
          Confirm
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <v-card v-else-if="action === 'reincorporate'" class='d-flex flex-column align-start pa-2'>
      <v-card-title class='text-center mb-5'>
        <v-icon class='mr-4 white--text' color='success' large left>
          mdi-alert
        </v-icon>
        Reincorporate Account
      </v-card-title>
      <v-card-subtitle>
        <b>
          You are about to reactivate your account, are you sure you want to proceed?
        </b>
      </v-card-subtitle>
      <v-card-actions class='align-self-center'>
        <v-spacer></v-spacer>
        <v-btn
          class='px-14 mx-4 font-weight-bold white--text'
          color='primary'
          large
          outlined
          @click="$emit('toggleStatusDialog', 'reincorporate')"
        >
          Cancel
        </v-btn>
        <v-btn
          class='px-14 mx-4 font-weight-bold white--text'
          color='primary'
          large
          @click="$emit('changeAccountStatus', 'reincorporate')"
        >
          Confirm
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <v-card v-else class='d-flex flex-column align-start pa-2'>
      <v-card-title class='text-center mb-5'>
        <v-icon class='mr-4 white--text' color='warning' large left>
          mdi-alert
        </v-icon>
        Warning!
      </v-card-title>
      <v-card-subtitle>
        <b>
          The entered address may not belong to a real address, do you want to continue with the operation?
        </b>
      </v-card-subtitle>
      <v-card-actions class='align-self-center'>
        <v-spacer></v-spacer>
        <v-btn
          class='px-14 mx-4 font-weight-bold white--text'
          color='primary'
          large
          outlined
          @click="$emit('closeDialog')"
        >
          Cancel
        </v-btn
        >
        <v-btn
          class='px-14 mx-4 font-weight-bold white--text'
          color='primary'
          large
          @click="$emit('confirmEditProfile')"
        >
          Confirm
        </v-btn>

        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'SuspendReincorporateDialog',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    action: {
      type: String
    }
  }
}
</script>

<style scoped>

</style>