<template>
  <v-alert
    v-model='customAlertData.show'
    :color='customAlertData.color'
    class='rounded-xl fix'
    dark
    dense
    dismissible
    icon='mdi-check-circle-outline'
    prominent
    transition='scale-transition'
    width='100%'
  >
    <h3>{{ customAlertData.message }}</h3>
  </v-alert>
</template>

<script>
import {mapMutations, mapState} from 'vuex'

export default {
  name: 'CustomAlert',
  computed: {
    ...mapState('employeeStore', ['customAlertData'])
  },
  watch: {
    customAlertData() {
      if (this.customAlertData.show) this.time()
    }
  },
  methods: {
    ...mapMutations('employeeStore', ['SET_CUSTOM_ALERT_DATA']),
    time() {
      const time = this.customAlertData.timeout ? this.customAlertData.timeout : 4000
      setTimeout(() => this.closeAlert(), time)
    },
    closeAlert() {
      this.SET_CUSTOM_ALERT_DATA({
        show: false,
        color: 'grey',
        message: ''
      })
    }
  }
}
</script>

<style scoped>
.v-alert {
  position: fixed;
  right: 10px;
  bottom: 10px;
  margin: 0 auto;
  z-index: 10;
  width: 50% !important;
}
</style>