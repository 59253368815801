<template>
  <div v-frag>
    <v-row class='ma-5' justify='center'>
      <v-card :loading='loadingProfile' class='pa-5 rounded-lg' elevation='3' width='800'>
        <v-row class='d-flex flex-row align-center justify-center mt-6'>
          <v-avatar v-if="employeeProfile.profilePicture !== ''" class='elevation-3' size='100'>
            <img :src='employeeProfile.profilePicture' alt='profilePicture'/>
          </v-avatar>
          <v-avatar
            v-else
            class='primary elevation-3'
            size='150'
          ></v-avatar>
          <v-card-title>
            {{ employeeProfile.firstName }} {{ employeeProfile.lastName }}
          </v-card-title>
        </v-row>
        <v-row class='d-flex align-center justify-center mt-16 mx-6'>
          <v-btn
            :disabled='loadingProfile'
            class='text-capitalize font-weight-bold px-14 py-6'
            color='primary'
            outlined
            width='100%'
            @click='toggleEditProfileDialog'
          >
            {{ sections.edit_profile || 'Edit Profile' }}
          </v-btn>
        </v-row>
        <v-simple-table class='mt-5'>
          <template #default>
            <tbody>
            <tr
              v-for='item in profileData'
              :key='item.name'
            >
              <td>{{ item.text }}</td>
              <td v-if="item.text !== 'County'" class='d-flex align-center justify-end'>{{ item.value }}</td>
              <td v-else class='d-flex align-center justify-end'>{{ item.value.text }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-row>
    <edit-profile-dialog
      :isOpen='isEditProfileDialogOpen'
      @closeDialog='isEditProfileDialogOpen = false'
      @getEmployeeProfile='initialize'
      @toggleDialog='toggleEditProfileDialog'
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import EditProfileDialog from './EditProfileDialog'

export default {
  name: 'EmployeeProfileTable',
  components: { EditProfileDialog },
  data: () => ({
    loadingProfile: false,
    isEditProfileDialogOpen: false
  }),
  async created() {
    await this.initialize()
  },
  computed: {
    ...mapState({
      employeeProfile: state => state.employeeStore.employeeProfile,
      sections: state => state.cmsStore.sections
    }),
    profileData() {
      return [
        // {
        //   text: 'Home Phone',
        //   value: this.employeeProfile.homePhone
        // },
        // {
        //   text: 'Work Phone',
        //   value: this.employeeProfile.workPhone
        // },
        {
          text: 'Mobile Phone',
          value: this.employeeProfile.mobilePhone
        },
        {
          text: 'Primary Email',
          value: this.employeeProfile.email
        },
        // {
        //   text: 'Secondary Email',
        //   value: this.employeeProfile.secondaryEmail
        // },
        {
          text: 'Address',
          value: this.employeeProfile.mailAddress
        },
        {
          text: 'City',
          value: this.employeeProfile.mailCity
        },
        {
          text: 'State',
          value: this.employeeProfile.mailState
        },
        {
          text: 'Zip Code',
          value: this.employeeProfile.mailZip
        },
        {
          text: 'County',
          value: this.employeeProfile.county
        },
        {
          text: 'Birth Year',
          value: this.employeeProfile.birthDate
        },
        {
          text: 'VUID',
          value: this.employeeProfile.vuid
        }
      ]
    }
  },
  methods: {
    ...mapActions('employeeStore', ['getEmployeeProfile']),
    async initialize() {
      this.loadingProfile = true
      await this.getEmployeeProfile()
      this.loadingProfile = false
    },
    toggleEditProfileDialog() {
      this.isEditProfileDialogOpen = !this.isEditProfileDialogOpen
      if (this.isEditProfileDialogOpen) {
        this.formData = Object.assign({}, this.employeeProfile)
      } else this.editedItem = {}
    }
  }
}
</script>

<style></style>