<template>
  <v-menu
    ref='menu'
    v-model='isOpen'
    :close-on-content-click='false'
    :return-value.sync='selectedYear'
    min-width='auto'
    offset-y
    transition='scale-transition'
  >
    <template #activator={on,attrs}>
      <v-text-field
        v-model='selectedYear'
        :disabled='disabled'
        dense
        outlined
        :placeholder='placeholder'
        prepend-inner-icon='mdi-calendar'
        readonly
        v-bind='attrs'
        v-on='on'
      ></v-text-field>
    </template>
    <v-date-picker
      ref='picker'
      v-model='selectedYear'
      :max='maxYear'
      min='1900'
      no-title
      scrollable
      @click:year='saveYear'
    >
      <template #default>
        <v-row no-gutters class='d-flex align-center justify-center'>
          <v-btn @click='emptyDate' text>Delete</v-btn>
        </v-row>
      </template>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'year-picker',
  props: {
    value: {
      type: [String, Number],
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'YYYY'
    }
  },
  data() {
    return {
      isOpen: false,
      selectedYear: null
    }
  },
  computed: {
    maxYear() {
      return (new Date().getFullYear() - 18).toString()
    }
  },
  watch: {
    isOpen(val) {
      val && this.$nextTick(() => this.$refs.picker.internalActivePicker = 'YEAR')
    },
    value: {
      handler(newVal) {
        this.selectedYear = newVal
      },
      immediate: true
    }
  },
  methods: {
    emptyDate() {
      this.selectedYear = ''
      this.$refs.menu.save('')
      this.$emit('input', '')
      this.isOpen = false
    },
    saveYear(year) {
      this.$refs.menu.save(year.toString())
      this.$emit('input', year)
      this.isOpen = false
    }
  }
}
</script>

<style scoped>

</style>