<template>
  <v-dialog
    v-model='isOpen'
    max-width='500px'
    persistent
    scrollable
    transition='dialog-bottom-transition'
  >
    <v-card>
      <v-card-title></v-card-title>
      <v-card-text>
        <clipper-fixed
          ref='clipper'
          :round='true'
          :src='imagePreview'
        ></clipper-fixed>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class='px-14 mx-4 font-weight-bold'
          color='primary'
          large
          outlined
          @click="$emit('closeDialog')"
        >
          Cancel
        </v-btn>
        <v-btn
          class='px-14 mx-4 font-weight-bold white--text'
          color='primary'
          large
          @click='cropImage'
        >
          Accept
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CropDialog',
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    imagePreview: {}
  },
  methods: {
    cropImage() {
      this.$refs.clipper.clip()
        .toBlob((blob) => {
          this.$emit('cropImage', blob)
          this.$emit('closeDialog')
        })
    }
  }
}
</script>

<style scoped>

</style>