<template>
  <div v-frag>
    <custom-alert/>
    <employee-profile-table/>
<!--    <employee-tabs-table :is-profile-page='true'/>-->
  </div>
</template>

<script>
import EmployeeProfileTable from './components/EmployeeProfileTable'
import CustomAlert from './components/CustomAlert'
// import EmployeeTabsTable from './components/EmployeeTabsTable'

export default {
  name: 'EmployeeProfile',
  components: { CustomAlert, EmployeeProfileTable}
}
</script>

<style scoped>

</style>