<template>
  <div v-frag>
    <v-dialog
      v-model='isOpen'
      max-width='1000px'
      transition='dialog-bottom-transition'
    >
      <v-form ref='form' v-model='isFormValid'>
        <v-card class='pt-8'>
          <v-card-title class='px-7 py-0 mb-6'>
            <span class='headline font-weight-bold'>{{ employeeProfile.firstName }} {{
                employeeProfile.lastName
              }}</span>
          </v-card-title>
          <v-row class='ma-0 px-4'>
            <v-col cols='6' class='py-0'>
              <p class='font-weight-bold'>First Name</p>
              <v-text-field
                v-model='formData.firstName'
                :rules="[(v) => !!v || 'Field is required']"
                dense
                outlined
                placeholder='i.e John'
                required
              ></v-text-field>
            </v-col>
            <v-col cols='6' class='py-0'>
              <p class='font-weight-bold'>Middle Name</p>
              <v-text-field
                v-model='formData.middleName'
                dense
                outlined
                placeholder='i.e Phil'
              ></v-text-field>
            </v-col>
            <v-col cols='6' class='py-0'>
              <p class='font-weight-bold'>Last Name</p>
              <v-text-field
                v-model='formData.lastName'
                :rules="[(v) => !!v || 'Field is required']"
                dense
                outlined
                placeholder='i.e Williams'
                required
              ></v-text-field>
            </v-col>
            <v-col cols='6' class='py-0'>
              <p class='font-weight-bold'>Birth Year</p>
              <year-picker v-model='formData.birthDate'/>
            </v-col>
            <v-col cols='6' class='py-0'>
              <p class='font-weight-bold'>Mobile Phone</p>
              <v-text-field
                v-model='formData.mobilePhone'
                :rules='phoneRules'
                dense
                outlined
                placeholder='i.e 2123456789'
              ></v-text-field>
            </v-col>
            <v-col cols="6"></v-col>
            <v-col cols='12' class='pb-5 pt-0'>
              <hr>
            </v-col>
            <v-col cols='6' class='py-0'>
              <p class='font-weight-bold'>Address</p>
              <v-text-field
                id='addressInput'
                ref='addressInput'
                v-model='formData.mailAddress'
                :rules='addressRules'
                dense
                outlined
                placeholder='i.e Lincoln Square 123'
              ></v-text-field>
            </v-col>
            <v-col cols='6' class='py-0'>
              <p class='font-weight-bold'>City</p>
              <v-text-field
                id='cityInput'
                ref='cityInput'
                v-model='formData.mailCity'
                :rules='cityRules'
                dense
                outlined
                placeholder='i.e Chicago'
              ></v-text-field>
            </v-col>
            <v-col cols='6' class='py-0'>
              <p class='font-weight-bold'>State</p>
              <v-text-field
                id='stateInput'
                ref='stateInput'
                v-model='formData.mailState'
                :rules='stateRules'
                dense
                outlined
                placeholder='i.e Illinois'
              ></v-text-field>
            </v-col>

            <v-col cols='6' class='py-0'>
              <p class='font-weight-bold'>Zip Code</p>
              <v-text-field
                id='zipCodeInput'
                ref='zipCodeInput'
                v-model='formData.mailZip'
                :rules='zipCodeRules'
                dense
                outlined
                placeholder='i.e 9851123'
              ></v-text-field>
            </v-col>
            <v-col cols='6' class='py-0'>
              <p class='font-weight-bold'>County</p>
              <v-select
                id='countyInput'
                ref='countyInput'
                v-model='formData.county'
                :items='[{ value: null, text: "" }, ...this.itemsCounty]'
                class='mt-3'
                dense
                label='County'
                outlined
                placeholder='County'
                required
              ></v-select>
            </v-col>
            <v-col cols="6"></v-col>
            <v-col cols='12' class='pb-5 pt-0'>
              <hr>
            </v-col>
            <v-col cols='6' class='py-0'>
              <p class='font-weight-bold'>Primary Email</p>
              <v-text-field
                v-model='formData.email'
                :rules="[(v) => !!v || 'Field is required']"
                dense
                outlined
                placeholder='i.e john@mydomain.com'
                required
              ></v-text-field>
            </v-col>
            <v-col cols='6' class='py-0'>
              <p class='font-weight-bold'>VUID</p>
              <v-text-field
                v-model='formData.vuid'
                dense
                outlined
                placeholder='i.e 9851123'
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class='ma-0 px-4'>
            <v-col>
              <p class='font-weight-bold'>Add a profile picture</p>
              <p class='font-weight-light'>JPG, PNG, file*</p>
              <v-row>
                <v-col md='3'>
                  <v-avatar
                    v-if="imageProfile === ''"
                    class='primary elevation-3'
                    size='150'
                  >
                  </v-avatar>
                  <v-avatar
                    v-else
                    class='elevation-3'
                    size='150'
                  >
                    <img :src='imageProfile' alt='profilePicture'/>
                  </v-avatar>
                </v-col>
                <v-col class='d-flex flex-column'>
                  <v-file-input
                    accept='image/png, image/jpeg, image/bmp'
                    chips
                    class='d-flex justify-end mt-16'
                    dense
                    outlined
                    placeholder='Add a file or drop it here'
                    prepend-icon=''
                    prepend-inner-icon='mdi-paperclip'
                    show-size
                    truncate-length='50'
                    @change='selectFile'
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class='ma-0 px-4'>
            <v-col>
                  <span>
                    <p class='font-weight-bold'>Notification Settings</p>
                  </span>
              <span class=' d-flex flex-row align-center justify-start mt-n5 ml-4'>
                    <p class='font-weight-lights mr-4'>SMS</p>
                    <v-switch
                      v-model='formData.smsNotificacion'
                      class='mb-3 ml-1'
                      primary
                    >
                    </v-switch>
                  </span>
              <span>
                    <p class='font-weight-bold'>Account Settings</p>
                  </span>
              <span class='d-flex flex-column align-start justify-start ml-4'>
                    <v-btn
                      v-if='accountActive'
                      class='pt-4'
                      text
                      @click="toggleStatusDialog('suspend')"
                    >
                      <p class='font-weight-light'>Suspend Account</p>
                    </v-btn>
                    <v-btn
                      v-else
                      class='pt-4'
                      text
                      @click="toggleStatusDialog('reincorporate')"
                    >
                      <p class='font-weight-light'>Reincorporate Account</p>
                    </v-btn>
                  </span>
            </v-col>
          </v-row>
          <v-card-actions class='d-flex direction-row justify-center'>
            <v-btn
              class='px-14 ma-4 font-weight-bold'
              color='primary'
              large
              outlined
              @click="$emit('closeDialog')"
            >
              Cancel
            </v-btn>
            <v-btn
              :loading='loadingSubmit'
              class='px-14 ma-4 font-weight-bold white--text'
              color='primary'
              large
              @click='onSubmit'
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <suspend-reincorporate-dialog
      :action='action'
      :isOpen='isDialogOpen'
      @changeAccountStatus='changeAccountStatus'
      @closeDialog='closeDialog'
      @confirmEditProfile='confirmEditProfile'
      @toggleStatusDialog='toggleStatusDialog'
    />
    <crop-dialog
      :imagePreview='imagePreview'
      :isOpen='isCropDialogOpen'
      @closeDialog='isCropDialogOpen = false'
      @cropImage='cropImage'
    />
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import SuspendReincorporateDialog from './SuspendReincorporateDialog'
import helper from '../../../services'
import CropDialog from './CropDialog'
import YearPicker from '@/components/year-picker'

export default {
  name: 'EditProfileDialog',
  components: {YearPicker, CropDialog, SuspendReincorporateDialog},
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loadingSubmit: false,
    isFormValid: false,
    formData: {},
    isMenuOpen: false,
    date: '',
    itemsCounty: [],
    imagePreview: '',
    imageProfile: '',
    fileType: '',
    accountActive: false,
    isDialogOpen: false,
    isCropDialogOpen: false,
    action: '',
    coordinates: {
      latitude: null,
      longitude: null
    },
    addressRules: [(v) => !!v || 'Address is required'],
    cityRules: [(v) => !!v || 'City is required'],
    stateRules: [(v) => !!v || 'State is required'],
    zipCodeRules: [(v) => !!v || 'Zip code is required'],
    phoneRules: [
      v => (!!v === false || /^\d+$/.test(v)) || "Value must be a number",
      v => (!!v === false || v.length === 0 || v.length === 10) || "Value must be equal to 10 characters",
    ]
  }),
  async created() {
    await this.getEmployeeId()
    await this.getEmployeesToReport(this.$store.getters.user.profile_id.member_id)
    this.formData = Object.assign({}, this.employeeProfile)
    this.imagePreview = this.employeeProfile.profilePicture
    this.imageProfile = this.employeeProfile.profilePicture
    this.accountActive = this.employeeProfile.active
    this.itemsCounty = await this.getCounties()
  },
  computed: {
    ...mapState('employeeStore', ['employeeProfile']),
    ...mapGetters('employeeStore', ['getFilteredEmployeesToReport'])
  },
  methods: {
    ...mapActions('employeeStore',
      [
        'getEmployeeId',
        'getEmployeesToReport',
        'enableDisableEmployee',
        'validateAddress',
        'editEmployee'
      ]),
    ...mapActions('communicationStore', ['getCounties']),
    ...mapMutations('employeeStore', ['SET_CUSTOM_ALERT_DATA']),
    selectFile(file) {
      if (file) {
        this.imagePreview = URL.createObjectURL(file)
        this.fileType = file.type
        this.isCropDialogOpen = true
      }
    },
    toggleStatusDialog(action) {
      this.action = action
      this.isDialogOpen = !this.isDialogOpen
    },
    async changeAccountStatus(changeTo) {
      if (changeTo === 'suspend') {
        this.accountActive = false
        this.SET_CUSTOM_ALERT_DATA({
          show: true,
          color: 'green',
          message: 'Account suspended successfully!'
        })
      }
      if (changeTo === 'reincorporate') {
        this.accountActive = true
        this.SET_CUSTOM_ALERT_DATA({
          show: true,
          color: 'green',
          message: 'Account was reactivated successfully!'
        })
      }
      await this.enableDisableEmployee()
      this.toggleStatusDialog(changeTo)
    },
    async onSubmit() {
      this.loadingSubmit = true
      const validAddress = await this.isAddressValid()
      if (this.$refs.form.validate()) {
        if (validAddress) {
          await this.confirmEditProfile()
        } else
          this.isDialogOpen = true
      }
    },
    async isAddressValid() {
      const data = {
        address: this.$refs.addressInput.value,
        state: this.$refs.stateInput.value,
        city: this.$refs.cityInput.value,
        postal_code: this.$refs.zipCodeInput.value,
        country: 'USA'
      }
      const res = await this.validateAddress(data)
      if (res.results && res.results.length > 0) {
        this.coordinates = {
          latitude: res.results[0].geometry.location.lat,
          longitude: res.results[0].geometry.location.lng
        }
        return true
      }
      return false
    },
    async confirmEditProfile() {
      this.isDialogOpen = false
      await this.saveEmployee()
      this.$emit('toggleDialog')
    },
    async saveEmployee() {
      try {
        if (this.formData.hierarchy.id)
          this.formData.hierarchy = this.formData.hierarchy.id
        if (this.formData.reportsTo.id)
          this.formData.reportsTo = this.formData.reportsTo.id
        const formData = {
          id: this.formData.id,
          firstName: this.formData.firstName,
          middleName: this.formData.middleName,
          lastName: this.formData.lastName,
          userName: this.formData.userName,
          email: this.formData.email,
          secondaryEmail: this.formData.secondaryEmail,
          homePhone: this.formData.homePhone,
          workPhone: this.formData.workPhone,
          mobilePhone: this.formData.mobilePhone,
          mailAddress: this.formData.mailAddress,
          mailCity: this.formData.mailCity,
          county_id: this.formData.county.value || this.formData.county,
          mailState: this.formData.mailState,
          latitude: this.coordinates.latitude,
          longitude: this.coordinates.longitude,
          vuid: this.formData.vuid,
          mailZip: this.formData.mailZip,
          birthDate: this.formData.birthDate,
          hierarchy: this.formData.hierarchy,
          reportsTo: this.formData.reportsTo,
          profilePictureObject: this.formData.profilePicture,
          emailNotification: this.formData.emailNotification,
          smsNotificacion: this.formData.smsNotificacion,
          fileType: this.fileType
        }
        await this.editEmployee(formData)
        await this.$store.dispatch('image')
        this.$emit('getEmployeeProfile')
      } catch (e) {
        this.loadingSubmit = false
        await helper.errorHandler(e.response)
      }
      this.loadingSubmit = false
    },
    cropImage(blob) {
      this.formData.profilePicture = blob
      this.imageProfile = URL.createObjectURL(blob)
      this.imagePreview = URL.createObjectURL(blob)
    },
    closeDialog() {
      this.loadingSubmit = false
      this.isDialogOpen = false
    }
  }
}
</script>

<style scoped>

</style>
